.i15owc4u{display:grid;gap:1.5rem;grid-template-columns:1fr 1fr;grid-auto-rows:auto;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;justify-items:center;max-width:var(--bp-l);}@media (max-width:53.99875em){.i15owc4u{grid-template-columns:1fr;}}
.bftudh4{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-flow:column;-ms-flex-flow:column;flex-flow:column;width:100%;gap:1.5rem;}
.b1eg9vb9{margin-top:-1.25rem;}
.f1up9qyz{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;max-width:27rem;}
.m15z4uxn > *{display:block !important;}
.i1tji6m:nth-child(n + 2){border-top:var(--rpx) solid var(--border);}
.tqc03mx{-webkit-flex:1 1 auto;-ms-flex:1 1 auto;flex:1 1 auto;}
.i1vj4k2z{-webkit-flex:0 0 auto;-ms-flex:0 0 auto;flex:0 0 auto;-webkit-transform:rotate(-90deg);-ms-transform:rotate(-90deg);transform:rotate(-90deg);-webkit-transition:-webkit-transform 100ms ease-in-out;-webkit-transition:transform 100ms ease-in-out;transition:transform 100ms ease-in-out;font-size:1.5rem;}
.b18klmek{all:unset;box-sizing:border-box;background:none;border:none;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:100%;gap:1rem;padding:1.25rem 0.75rem;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;cursor:pointer;font-family:var(--font-body);font-size:1.5rem;font-weight:500;font-feature-settings:"wght" 500;text-align:left;-webkit-transition:color 100ms ease-in-out;transition:color 100ms ease-in-out;}.b18klmek:hover{color:var(--hover);}.b18klmek[aria-expanded="true"] .i1vj4k2z{-webkit-transform:rotate(0);-ms-transform:rotate(0);transform:rotate(0);}
.bebm46s{padding:0 0.75rem 1.25rem 1.5rem;-webkit-flex-flow:column;-ms-flex-flow:column;flex-flow:column;width:100%;gap:1.5rem;}.bebm46s[aria-expanded="true"]{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
